import { createTheme } from '@mui/material';

const FONT_PRIMARY = [
  'Public Sans',
  'Montserrat Alternates',
  'Montserrat',
  'sans-serif'
].join(',');

const FONT_SECONDARY = ['Public Sans', 'sans-serif'].join(',');

const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          fontFamily: FONT_PRIMARY,
          backgroundColor: '#5e5e5ec9'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: FONT_SECONDARY,
          textTransform: 'capitalize',
          boxShadow: `0 3px 3px rgba(0, 0, 0, 0.125)`,
          borderRadius: '1rem',
          fontWeight: 'bold',
          fontSize: '0.8rem'
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#161616',
            color: '#fff',
            fontFamily: FONT_SECONDARY
          }
        },
        containedInfo: {
          backgroundColor: '#161616',
          color: '#fff',
          fontFamily: FONT_SECONDARY,
          '&:hover': {
            backgroundColor: '#88F231',
            color: '#fff'
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        color: '#000',
        h4: {
          borderLeft: '0.6rem #88F231 solid',
          fontFamily: 'Montserrat Alternates, sans-serif',
          paddingLeft: '0.5rem',
          color: '#161616',
          fontWeight: 700
        },
        subtitle1: {
          fontWeight: 600,
          fontSize: '1.15rem',
          lineHeight: 1.2,
          fontFamily: '"Montserrat Alternates", sans-serif',
          color: '#28282885'
        },
        body1: {
          fontWeight: 'bold',
          lineHeight: 1.2,
          fontFamily: '"Montserrat Alternates", sans-serif',
          color: '#161616'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          '&:hover fieldset': {
            borderColor: '#88F231'
          },
          '&.Mui-focused fieldset': {
            borderColor: '#88F231'
          },
          '&.Mui-error fieldset': {
            borderColor: '#dc3545'
          },
          '& fieldset': {
            borderColor: '#f0f0f0'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#88F231'
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#88F231'
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#88F231'
          }
        },
        input: {
          '&:-webkit-autofill': {
            appearance: 'none',
            WebkitBackgroundClip: 'text',
            WebkitBoxShadow: '0 0 0 100px transparent inset',
            backgroundImage: 'none !important',
            backgroundColor:
              'light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important',
            color: 'fieldtext !important'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // color: '#6c757d',
          // fontWeight: 500,
          '&.Mui-focused': {
            color: '#121212'
          },
          '&.Mui-error': {
            color: '#121212'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem'
        },
        colorSuccess: {
          backgroundColor: '#88F231',
          fontWeight: 600,
          color: '#fff'
        },
        colorError: {
          backgroundColor: '#dc3545',
          fontWeight: 600,
          color: '#fff'
        },
        colorInfo: {
          backgroundColor: '#f1c40f',
          color: '#000'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#000 !important',
            fontWeight: 'bold !important'
          },
          '&:hover': {
            color: '#88F231 !important'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#88F231 !important'
        }
      }
    }
  },

  typography: {
    fontFamily: FONT_PRIMARY
  },
  palette: {
    primary: {
      main: '#88F231 !important'
    },
    text: {
      main: '#161616'
    }
  }
});

export default theme;
