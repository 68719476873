import { Box, Paper, styled, Typography } from '@mui/material';

export const StyleBox = styled(Box)({
  background: '#f9f9f9',
  padding: '0.2rem',
  marginTop: '0.4rem',
  borderRadius: '0.4rem'
});

export const StyleHeader = styled(Typography)(({ theme }) => ({
  marginTop: '0.6rem',
  textTransform: 'capitalize',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.h6.fontSize,
  borderBottom: '2px solid #d3d3d3',
  borderRadius: '3px'
}));

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow:
    '-1px 2px 6px 6px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027'
  })
}));

export const LogoWrapper = styled(Box)({
  display: 'flex',
  border: '1px solid #f0f0f0',
  borderRadius: '0.8rem',
  padding: '0.62rem',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export const LogoFileLabel = styled(Typography)({
  marginTop: 0.4,
  marginLeft: 14,
  fontSize: '0.85rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '224px'
});
