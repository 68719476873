/* eslint-disable react/display-name */
import { memo, useCallback, useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Box } from '@mui/material';
import TopBar from '../TopBar';
import logo from 'src/assets/images/solfin-logo.svg';

const Header = ({ isSticky }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [applyLoanUrl, setApplyLoanUrl] = useState('');

  const hideLoanProducts = () => {
    const loanProductMenu = document.getElementById('loan-prod-sub-menu');
    if (loanProductMenu) loanProductMenu.style.display = 'none';
  };

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleNavLinkClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const currentDomain = window.location.hostname;
    setApplyLoanUrl(
      currentDomain.includes('stg') || currentDomain.includes('localhost')
        ? 'https://stg.finance.solfin.co.in'
        : 'https://finance.solfin.co.in'
    );
  }, []);

  return (
    <header
      className={`smooth position-absolute start-0 top-0 w-100 pt-md-3 pt-1 mt-1 ${isSticky ? 'sticky-header' : ''}`}
      onClick={hideLoanProducts}
      id="headerContainer"
    >
      <Box className="container custom-container">
        <div className="header-wrap rounded-20">
          <div className="top-header text-white bg-black pt-1 pb-1">
            <TopBar />
          </div>
          <div className="main-header mx-lg-4 mx-md-2 mx-sm-0">
            <div className="row align-items-lg-center justify-content-between">
              <div className="col">
                <div className="solfin-logo px-1">
                  <Link to="/">
                    <img src={logo} alt="Solfin Logo" title="Solfin" />
                  </Link>
                </div>
                <div className="d-flex align-items-center px-2">
                  <div className="touch-in-solfin d-xl-none me-2">
                    <Link
                      to="/login"
                      className="btn btn-primary btn-header rounded-5 text-nowrap"
                      onClick={handleNavLinkClick}
                    >
                      Login
                    </Link>
                  </div>
                  <button
                    className="navbar-toggler d-xl-none"
                    type="button"
                    onClick={handleMenuToggle}
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation"
                  >
                    <span className="fa fa-bars"></span>
                  </button>
                </div>
              </div>
              <div className="col">
                <nav className="navbar navbar-expand-xl p-0" id="solfin-nav">
                  <div className="container-fluid">
                    <div
                      className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav me-auto mb-2 mb-xl-0">
                        <li className="nav-item">
                          <NavLink
                            className="nav-link"
                            to="/"
                            onClick={handleNavLinkClick}
                          >
                            Home
                          </NavLink>
                        </li>
                        <DropdownNavItem
                          title="I Want Solar"
                          links={[
                            { to: '/estimator', label: 'My Savings' },
                            { to: '/register', label: 'Register' }
                          ]}
                          handleNavLinkClick={handleNavLinkClick}
                        />
                        <LoanProductsNavItem
                          applyLoanUrl={applyLoanUrl}
                          handleNavLinkClick={handleNavLinkClick}
                        />
                        <DropdownNavItem
                          title="Partners"
                          links={[
                            {
                              to: '/premiumPartners',
                              label: 'Premium Partners'
                            },
                            { to: '/epc', label: 'EPC Partners' },
                            { to: '/dsa', label: 'DSA Partners' }
                          ]}
                          handleNavLinkClick={handleNavLinkClick}
                        />
                        <li className="nav-item">
                          <NavLink
                            to="/mission"
                            className="nav-link"
                            onClick={handleNavLinkClick}
                          >
                            Mission
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col d-none d-xl-block right-col">
                <div className="right-header d-flex align-items-center justify-content-end">
                  <div className="touch-in-solfin ms-xxl-5 ms-3">
                    <Link
                      to="/login"
                      className="btn btn-primary btn-header rounded-5 text-nowrap"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </header>
  );
};

const DropdownNavItem = memo(({ title, links, handleNavLinkClick }) => (
  <li className="nav-item dropdown">
    <a
      className="nav-link dropdown-toggle"
      href="#"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {title}
    </a>
    <ul className="dropdown-menu">
      {links.map((link, index) => (
        <li key={index}>
          <NavLink
            to={link.to}
            className="dropdown-item"
            onClick={handleNavLinkClick}
          >
            {link.label}
          </NavLink>
        </li>
      ))}
    </ul>
  </li>
));

const LoanProductsNavItem = memo(({ applyLoanUrl, handleNavLinkClick }) => {
  const toggleMenu = useCallback((menuId) => {
    const element = document.getElementById(menuId);
    if (element) {
      element.style.display =
        element.style.display === 'none' || !element.style.display
          ? 'block'
          : 'none';
    }
  }, []);

  return (
    <li className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Finance
      </a>
      <ul className="dropdown-menu">
        <li>
          <a
            href="#"
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleMenu('apply-loan-sub-menu');
            }}
          >
            Apply for Loan
          </a>
          <ul className="loan-prod-menu dropdown-menu" id="apply-loan-sub-menu">
            <li>
              <a
                href={`${applyLoanUrl}/onboard/#/home`}
                className="dropdown-item"
              >
                Residential
              </a>
            </li>
            <li>
              <a href="/contact-sales" className="dropdown-item">
                Commercial and Industrial
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              toggleMenu('loan-prod-sub-menu');
            }}
          >
            Loan Products
          </a>
          <ul className="loan-prod-menu dropdown-menu" id="loan-prod-sub-menu">
            <li>
              <a href="/loan-products/residential" className="dropdown-item">
                Residential Rooftop
              </a>
            </li>
            <li>
              <a href="/coming-soon" className="dropdown-item">
                Housing Society Rooftop
              </a>
            </li>
            <li>
              <a href="/coming-soon" className="dropdown-item">
                Commercial and Industrial
              </a>
            </li>
            <li>
              <a href="/coming-soon" className="dropdown-item">
                EV Loan
              </a>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            to="/lease-contact-sales"
            className="dropdown-item"
            onClick={handleNavLinkClick}
          >
            Lease
          </NavLink>
        </li>
      </ul>
    </li>
  );
});

export default Header;
