import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';

import { useFormik } from 'formik';
import { object, string } from 'yup';
import { LoadingButton } from '@mui/lab';
import { TextField, Box, Grid2, CircularProgress } from '@mui/material';

import { submitQuery } from '../../api/dataServices';
import CustomModal from '../../components/CustomModal';

const Footer = ({ handleSubmit, isLoading }) => {
  return (
    <Box
      sx={{ width: '100%' }}
      className="d-flex align-items-center m-2 justify-content-end"
    >
      <LoadingButton
        loading={isLoading}
        className="btn btn-primary btn-header rounded-5 text-nowrap text-uppercase green-btn mx-2"
        onClick={handleSubmit}
        variant="contained"
        sx={{ minWidth: 152, minHeight: 40, fontSize: '0.85rem' }}
        loadingIndicator={<CircularProgress color="inherit" size={25} />}
      >
        Submit
      </LoadingButton>
    </Box>
  );
};

const LandingPage = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleUserQuery = useMutation(submitQuery, {
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.success(data?.data?.response);
      setModalOpen(false);
      formik.resetForm();
    }
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    },
    validationSchema: object({
      firstName: string().required('First Name is required'),
      lastName: string().required('Last Name is required'),
      email: string()
        .email('Invalid email format')
        .required('Email is required'),
      phone: string()
        .required('Mobile number is required')
        .matches(/^\d{10}$/, 'Invalid mobile number'),
      message: string()
        .max(500, 'Message can not be more than 500 Characters')
        .required('Message is required')
    }),
    onSubmit: (values) => {
      handleUserQuery.mutate(values);
    }
  });

  return (
    <>
      <div className="save-your-energy">
        <div className="container custom-container">
          <div className="row align-items-center justify-content-between flex-md-nowrap flex-wrap">
            <div className="col">
              <div className="save-energy-headline ps-xl-5 ps-4">
                <h3 className="fw-bold m-0 text-nowrap">
                  Want a <span className="text-theme-green">Cleaner</span> &{' '}
                  <span className="text-theme-green">Greener</span> tomorrow?
                </h3>
              </div>
            </div>
            <div className="col">
              <div className="right-energy-action">
                <Box
                  className="text-decoration-none"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  <span className="btn btn-primary calculate-btn fw-bold rounded-pill">
                    Let’s talk
                  </span>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          open={modalOpen}
          handleClose={() => setModalOpen(!modalOpen)}
          headerLabel="Contact Us"
          footerComponent={
            <Footer
              handleSubmit={formik.handleSubmit}
              isLoading={handleUserQuery.isLoading}
            />
          }
          modalMinHeight="27.4rem"
        >
          <Box sx={{ padding: '0.5rem 1rem', borderRadius: 2 }}>
            <Grid2 container rowSpacing={0} columnSpacing={2}>
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  margin="normal"
                />
              </Grid2>
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  margin="normal"
                />
              </Grid2>
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  margin="normal"
                />
              </Grid2>
              <Grid2 item size={{ xs: 12, sm: 6 }}>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  label="Phone"
                  type="tel"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  margin="normal"
                />
              </Grid2>
              <Grid2 item size={12}>
                <TextField
                  fullWidth
                  id="message"
                  name="message"
                  label="Message"
                  placeholder="Please enter you query..."
                  multiline
                  rows={3}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                  margin="normal"
                />
              </Grid2>
            </Grid2>
          </Box>
        </CustomModal>
      </div>
    </>
  );
};

export default LandingPage;
