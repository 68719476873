import gonda10KWPlantImage from 'src/assets/images/projectsInstalled/gonda-10kw-project-image.jpg';
import kushinagar17KWPlantImage from 'src/assets/images/projectsInstalled/kushinager-17kw-project-image.png';
import ceratecAntara70KWPlantImage from 'src/assets/images/projectsInstalled/CeratecAntara-70KW-Yewalewadi-Pune-project-image.jpg';


export const socialLinks = {
  linkedin: 'https://in.linkedin.com/company/solfin-sustainable-financing',
  twitter: 'https://twitter.com/solfin_india',
  facebook:
    'https://www.facebook.com/profile.php?id=61558828528270&mibextid=ZbWKwL'
};

export const epcRegistrationTypes = [
  { id: 1, name: 'Sole Prop', label: 'Sole Prop' },
  { id: 2, name: 'Other', label: 'Other' }
];

export const documentsCategory = {
  COMPANY_DOCUMENT: 'Company Document',
  SIGNATORY_DOCUMENT: 'Signatory Document'
};

export const distributorTabs = [
  { id: 1, label: 'New Tasks' },
  { id: 2, label: 'In Progress Tasks' }
];

export const ourProjectList = [
  {
    id: 1,
    location: 'Gonda, Uttar Pradesh',
    units: '10KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: gonda10KWPlantImage
  },
  {
    id: 2,
    location: 'kushinagar - Sukrauli, Uttar Pradesh',
    units: '17KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: kushinagar17KWPlantImage
  },
  {
    id: 3,
    location: 'Ceratec Antara, Pune, Maharashtra',
    units: '70KW',
    typeOfProject: 'Off-Grid Roof Top Solar',
    projectImageUrl: ceratecAntara70KWPlantImage
  }
];

export const howWeWorkSteps = [
  {
    title: 'System Design',
    text: 'EPC would conduct a site visit and design a system',
    icon: '/images/solar-panel.svg',
    alt: 'System Design'
  },
  {
    title: 'Consultation',
    text: 'Discuss with EPC, the project in detail on Kwh requirement, Net Metering, Storage etc.',
    icon: '/images/consultation.svg',
    alt: 'Consultation'
  },
  {
    title: 'Flexible Financing',
    text: 'Choose best finance options basis interest rate, tenure, via state of art Digital loan processing system',
    icon: '/images/payment-plan.svg',
    alt: 'Payment Plan'
  },
  {
    title: 'Installation',
    text: 'Customer can get the project installed after closure of EPC and Financing contracts.',
    icon: '/images/installation.svg',
    alt: 'Installation'
  },
  {
    title: 'Monitoring & Maintenance',
    text: 'Low cost maintenance through EPC and special maintenance partners',
    icon: '/images/monitoring-maintance.svg',
    alt: 'Monitoring & Maintenance'
  },
  {
    title: 'Plant Optimization & Expansion',
    text: 'Storage Options and utilization options',
    icon: '/images/battery-optimization.svg',
    alt: 'Battery Optimization'
  }
];

export const loanFinancingType = {
  RESIDENTIAL: 'Residential Lead',
  COMMERCIAL_AND_INDUSTRIAL: 'C & I Lead',
  PLL: 'Performance Linked Loan',
  OTHER: 'Housing Societies and Corporations / Other'
};

export const loanFinancingCategories = [
  {
    id: 'RESIDENTIAL',
    imageUrl:
      'https://png.pngtree.com/background/20231030/original/pngtree-contract-form-and-approved-home-loan-stunning-3d-illustration-picture-image_5808578.jpg',
    imageTitle: 'Residential Lead',
    isActive: true,
    title: 'Residential Lead'
  },
  {
    id: 'COMMERCIAL_AND_INDUSTRIAL',
    imageUrl:
      'https://img.freepik.com/premium-photo/3d-flat-icon-business-financial-concept-bankers-discussing-finance-services-loan-option_980716-68802.jpg',
    imageTitle: 'C & I',
    isActive: true,
    title: 'C & I'
  },
  {
    id: 'PLL',
    imageUrl:
      'https://png.pngtree.com/background/20231030/original/pngtree-contract-form-and-approved-home-loan-stunning-3d-illustration-picture-image_5808578.jpg',
    imageTitle: 'Performance Linked Loan',
    isActive: false,
    title: 'Performance Linked Loan'
  },
  {
    id: 'OTHER',
    imageUrl:
      'https://static.vecteezy.com/system/resources/previews/021/596/685/non_2x/3d-illustration-loan-in-credit-and-loan-set-free-png.png',
    imageTitle: 'Other',
    isActive: true,
    title: 'Housing Societies and Corporations / Other'
  }
];