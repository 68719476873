import { Container } from '@mui/material';
import { gsap } from 'gsap';
import { memo, useLayoutEffect } from 'react';

const Loader = ({ isBrandNameHidden = true }) => {
  useLayoutEffect(() => {
    const paths = ['#brand_1', '#brand_2', '#brand_3', '#brand_4', '#brand_5'];
    const logoPaths = [
      '#path_1',
      '#path_2',
      '#path_3',
      '#path_4',
      '#path_5',
      '#path_6',
      '#path_7',
      '#path_8'
    ];

    /* animation 01 for the outlined effect
    const path = document.querySelector('#currentPath');
    if (path) {
      const length = path.getTotalLength();
      gsap.set(path, {
        strokeDashoffset: length,
        stroke: '#fff',
        fill: '#000'
      });
      gsap.to(path, {
        strokeDashoffset: 0,
        duration: 2,
        ease: 'power1.inOut',
        repeat: -1,
        repeatDelay: 1
      });
    }

    paths.forEach((path, index) => {
      const pathElement = document.querySelector(path);

      if (pathElement) {
        const length = pathElement.getTotalLength();
        gsap.set(pathElement, {
          strokeDasharray: length,
          strokeDashoffset: length,
          stroke: '#07bc0c',
          fill: 'none'
        });

        gsap.to(pathElement, {
          strokeDashoffset: 0,
          duration: 2,
          delay: index * 0.5,
          ease: 'linear', // can set 'linear' or 'power1.inOut',
          repeat: -1,
          repeatDelay: 2,
          yoyo: false // To reverse the animation after completion for a "typing erase" effect
        });
      }
    });
  */

    // Animation 01 for the currentPath
    const currentPath = document.querySelector('#currentPath');

    if (currentPath && !isBrandNameHidden) {
      gsap.set(currentPath, {
        fill: '#000',
        fillOpacity: 0,
        scale: 1
      });
      gsap.to(currentPath, {
        fillOpacity: 1,
        scale: 1.1,
        duration: 1.8,
        delay: 0.05,
        ease: 'linear',
        repeat: -1,
        repeatDelay: 1,
        yoyo: false
      });
    }

    if (!isBrandNameHidden) {
      paths.forEach((path, index) => {
        const pathElement = document.querySelector(path);

        if (pathElement) {
          gsap.set(pathElement, {
            fill: '#000',
            fillOpacity: 0,
            strokeWidth: 1,
            scale: 0.9
          });

          gsap.to(pathElement, {
            fillOpacity: 1,
            scale: 1.05,
            strokeWidth: 4,
            duration: 2,
            delay: index * 0.08,
            ease: 'power1.inOut',
            repeat: -1,
            repeatDelay: 1,
            yoyo: false
          });
        }
      });
    }

    logoPaths.forEach((path, index) => {
      const pathElement = document.querySelector(path);

      if (pathElement) {
        gsap.set(pathElement, {
          fill: '#fff',
          fillOpacity: 0,
          strokeWidth: 0,
          stroke: '#6c757d'
        });

        gsap.to(pathElement, {
          fill: '#6c757d', // Change fill color smoothly
          stroke: '#000',
          fillOpacity: 1, // Fade the fill in
          strokeWidth: 1, // Increase stroke width smoothly
          delay: index * 0.06, // Stagger the animation slightly for each path
          ease: 'linear', // Smooth easing function
          repeat: -1, // Infinite loop
          repeatDelay: 1, // Delay between repeats
          yoyo: false, // Reverses the animation on repeat
          duration: 1, // Duration of each animation cycle
          // yoyoEase: 'power1.in'
        });
      }
    });
  }, []);

  return (
    <Container
      sx={{ height: '100vh' }}
      className="d-flex align-items-center justify-content-center"
    >
      <svg
        width="137"
        height="46"
        viewBox="0 0 137 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_13_109)">
          <mask
            id="mask0_13_109"
            style={{ maskType: 'luminance' }} // Fixed style here
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="137"
            height="46"
          >
            <path d="M137 0H0V46H137V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_13_109)">
            <mask
              id="mask1_13_109"
              style={{ maskType: 'luminance' }} // Fixed style here
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="46"
              height="46"
            >
              <path d="M46 0H0V46H46V0Z" fill="white" />
            </mask>
            <g mask="url(#mask1_13_109)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9988 40.5533C13.3197 40.5533 5.44531 32.6789 5.44531 22.9998C5.44531 13.3207 13.3197 5.44629 22.9988 5.44629C32.6779 5.44629 40.5523 13.3207 40.5523 22.9998C40.5523 32.6789 32.6779 40.5533 22.9988 40.5533Z"
                fill="#88F231"
              />
              <path
                id="currentPath"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.3567 33.1265C22.2781 33.1265 22.1978 33.1172 22.1192 33.0956C21.7135 32.9892 21.4297 32.6221 21.4297 32.201V25.539H17.5041C17.1709 25.539 16.8639 25.36 16.6989 25.0701C16.5338 24.7801 16.5385 24.4238 16.7097 24.1384L23.1665 13.3256C23.3794 12.9677 23.8051 12.7965 24.2062 12.9076C24.6088 13.0186 24.8864 13.3826 24.8864 13.7991V20.492H28.4866C28.8167 20.492 29.1221 20.6678 29.2871 20.9532C29.4537 21.237 29.4552 21.5887 29.2917 21.8756L23.1604 32.6591C22.9938 32.9537 22.6822 33.1265 22.3567 33.1265Z"
                fill="#161616"
              />
              <path
                d="M8.98286 9.74737C9.22966 9.48669 9.48417 9.23218 9.74485 8.98538L7.49745 6.73644C7.28613 6.52666 6.94524 6.52666 6.73546 6.73644C6.52568 6.94621 6.52568 7.28865 6.73546 7.49842L8.98286 9.74737Z"
                fill="#161616"
                id="path_1"
              />
              <path
                d="M37.012 9.74737L39.2609 7.49842C39.4707 7.2871 39.4707 6.94621 39.2609 6.73644C39.0512 6.52666 38.7087 6.52666 38.4989 6.73644L36.25 8.98538C36.5107 9.23218 36.7652 9.48669 37.012 9.74737Z"
                fill="#161616"
                id="path_2"
              />
              <path
                d="M22.9993 3.70197C23.1797 3.70197 23.3587 3.70505 23.5376 3.70968V0.538328C23.5376 0.240628 23.2954 0 22.9993 0C22.7016 0 22.4609 0.24217 22.4609 0.538328V3.70968C22.6399 3.70505 22.8188 3.70197 22.9993 3.70197Z"
                fill="#161616"
                id="path_3"
              />
              <path
                d="M3.70197 23.0002C3.70197 22.8198 3.70505 22.6408 3.70968 22.4619H0.538328C0.240628 22.4619 0 22.7041 0 23.0002C0 23.2979 0.24217 23.5386 0.538328 23.5386H3.70968C3.70505 23.3596 3.70197 23.1807 3.70197 23.0002Z"
                fill="#161616"
                id="path_4"
              />
              <path
                d="M22.9993 42.2977C22.8188 42.2977 22.6399 42.2947 22.4609 42.29V45.4614C22.4609 45.7591 22.7031 45.9997 22.9993 45.9997C23.297 45.9997 23.5376 45.7591 23.5376 45.4614V42.29C23.3587 42.2947 23.1797 42.2977 22.9993 42.2977Z"
                fill="#161616"
                id="path_5"
              />
              <path
                d="M45.4604 22.4619H42.2891C42.2937 22.6408 42.2968 22.8213 42.2968 23.0002C42.2968 23.1792 42.2937 23.3596 42.2891 23.5386H45.4604C45.7581 23.5386 45.9987 23.2964 45.9987 23.0002C45.9987 22.7025 45.7581 22.4619 45.4604 22.4619Z"
                fill="#161616"
                id="path_6"
              />
              <path
                d="M37.012 36.252C36.7652 36.5126 36.5107 36.7671 36.25 37.0139L38.4989 39.2629C38.6054 39.3678 38.7427 39.4202 38.8815 39.4202C39.0188 39.4202 39.1576 39.3678 39.2625 39.2629C39.4723 39.0531 39.4723 38.7107 39.2625 38.5009L37.012 36.252Z"
                fill="#161616"
                id="path_7"
              />
              <path
                d="M8.9844 36.2529L6.73546 38.5003C6.52568 38.7117 6.52568 39.0525 6.73546 39.2623C6.84035 39.3678 6.97863 39.4202 7.11742 39.4202C7.25621 39.4202 7.39349 39.3678 7.49838 39.2623L9.74732 37.0133C9.48764 36.7665 9.23313 36.512 8.9844 36.2529Z"
                fill="#161616"
                id="path_8"
              />
            </g>
            {!isBrandNameHidden ? (
              <>
                <path
                  d="M59.336 27.072V23.712H68.552C69.016 23.712 69.408 23.552 69.728 23.232C70.064 22.896 70.232 22.496 70.232 22.032C70.232 21.568 70.064 21.176 69.728 20.856C69.408 20.52 69.016 20.352 68.552 20.352H64.04C63.128 20.352 62.28 20.128 61.496 19.68C60.728 19.232 60.12 18.624 59.672 17.856C59.224 17.072 59 16.224 59 15.312C59 14.4 59.224 13.56 59.672 12.792C60.136 12.024 60.752 11.416 61.52 10.968C62.288 10.504 63.128 10.272 64.04 10.272H72.632V13.632H64.04C63.576 13.632 63.176 13.8 62.84 14.136C62.52 14.456 62.36 14.848 62.36 15.312C62.36 15.776 62.52 16.176 62.84 16.512C63.176 16.832 63.576 16.992 64.04 16.992H68.552C69.464 16.992 70.304 17.224 71.072 17.688C71.84 18.136 72.448 18.744 72.896 19.512C73.36 20.28 73.592 21.12 73.592 22.032C73.592 22.944 73.36 23.784 72.896 24.552C72.448 25.32 71.84 25.936 71.072 26.4C70.304 26.848 69.464 27.072 68.552 27.072H59.336Z"
                  id="brand_1"
                  fill="#fff"
                />
                <path
                  d="M83.5147 27.288C82.1707 27.288 80.9227 26.96 79.7707 26.304C78.6347 25.632 77.7307 24.728 77.0587 23.592C76.4027 22.44 76.0747 21.192 76.0747 19.848C76.0747 18.504 76.4027 17.264 77.0587 16.128C77.7307 14.976 78.6347 14.072 79.7707 13.416C80.9227 12.744 82.1707 12.408 83.5147 12.408C84.8587 12.408 86.0987 12.744 87.2347 13.416C88.3867 14.088 89.2987 14.992 89.9707 16.128C90.6427 17.264 90.9787 18.504 90.9787 19.848C90.9787 21.192 90.6427 22.44 89.9707 23.592C89.2987 24.728 88.3947 25.632 87.2587 26.304C86.1227 26.96 84.8747 27.288 83.5147 27.288ZM83.5147 23.928C84.2507 23.928 84.9307 23.744 85.5547 23.376C86.1947 23.008 86.6987 22.512 87.0667 21.888C87.4347 21.264 87.6187 20.584 87.6187 19.848C87.6187 19.112 87.4347 18.432 87.0667 17.808C86.6987 17.184 86.1947 16.688 85.5547 16.32C84.9307 15.952 84.2507 15.768 83.5147 15.768C82.7787 15.768 82.0987 15.952 81.4747 16.32C80.8507 16.688 80.3547 17.184 79.9867 17.808C79.6187 18.432 79.4347 19.112 79.4347 19.848C79.4347 20.584 79.6187 21.264 79.9867 21.888C80.3547 22.512 80.8507 23.008 81.4747 23.376C82.0987 23.744 82.7787 23.928 83.5147 23.928Z"
                  id="brand_2"
                  fill="#fff"
                />
                <path
                  d="M97.7038 27.072H94.3438V7.872H97.7038V27.072Z"
                  id="brand_3"
                  fill="#fff"
                />
                <path
                  d="M108.739 9.36C107.795 9.36 106.955 9.68 106.219 10.32C105.499 10.944 105.075 11.728 104.947 12.672H110.467V16.032H104.899V27.072H101.539V13.224C101.539 11.912 101.859 10.704 102.499 9.6C103.155 8.496 104.035 7.624 105.139 6.984C106.243 6.328 107.443 6 108.739 6C109.443 6 110.139 6.104 110.827 6.312C111.515 6.52 112.155 6.816 112.747 7.2L110.899 10.008C110.227 9.576 109.507 9.36 108.739 9.36ZM115.291 27.072V12.672H118.651V27.072H115.291ZM116.971 10.752C116.443 10.752 115.995 10.568 115.627 10.2C115.259 9.832 115.075 9.384 115.075 8.856C115.075 8.328 115.259 7.88 115.627 7.512C115.995 7.144 116.443 6.96 116.971 6.96C117.499 6.96 117.947 7.144 118.315 7.512C118.683 7.88 118.867 8.328 118.867 8.856C118.867 9.384 118.683 9.832 118.315 10.2C117.947 10.568 117.499 10.752 116.971 10.752Z"
                  id="brand_4"
                  fill="#fff"
                />
                <path
                  d="M129.297 12.408C130.625 12.408 131.857 12.744 132.993 13.416C134.145 14.088 135.057 15 135.729 16.152C136.401 17.288 136.737 18.52 136.737 19.848V27.072H133.377V19.848C133.377 19.128 133.185 18.456 132.801 17.832C132.433 17.208 131.937 16.712 131.313 16.344C130.689 15.96 130.017 15.768 129.297 15.768C128.145 15.768 127.153 16.208 126.321 17.088V27.072H122.961V17.04C122.961 16.64 123.009 16.288 123.105 15.984C123.201 15.664 123.361 15.368 123.585 15.096C124.289 14.264 125.137 13.608 126.129 13.128C127.121 12.648 128.177 12.408 129.297 12.408Z"
                  id="brand_5"
                  fill="#fff"
                />
              </>
            ) : null}
          </g>
        </g>
        <defs>
          <clipPath id="clip0_13_109">
            <rect width="137" height="46" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
};

export default memo(Loader, () => true);
